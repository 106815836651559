<template>
  <b-card>
    <div>
      <b-row class="p-1">
        <div v-if="!showCreate">
          <b-button
            variant="relief-success"
            class="mr-2"
            @click="showCreate = true"
          >
            <feather-icon icon="PlusIcon" />
            Cadastrar
          </b-button>
        </div>
        <div v-else>
          <b-button
            variant="relief-danger"
            class="mr-2"
            @click="showCreate = false"
          >
            <feather-icon icon="ListIcon" />
            Listar
          </b-button>
        </div>

        <div v-if="!showSearch" v-show="!showCreate">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showSearch = true"
            title="Pesquisar"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>
        <div v-else v-show="!showCreate">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showSearch = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
        <b-col v-show="showSearch">
          <b-form-input
            placeholder="Pesquisar"
            v-model="search"
            debounce="800"
            type="search"
          />
        </b-col>
      </b-row>
      <div v-show="!showCreate">
        <b-overlay :show="isLoading" opacity="0.70" rounded="sm">
          <b-table-simple
            striped
            stacked="md"
            noDataText="Você ainda não possui clientes cadastrados."
            class="mt-2 responsive"
            id="tableTenants"
            :item="fetchTenants"
            :per-page="perPage"
            :current-page="currentPage"
          >
            <b-thead class="text-center">
              <b-th> Nome da empresa </b-th>
              <b-th> Plano </b-th>
              <b-th> Status </b-th>
              <b-th> Data de registro </b-th>
              <b-th> Perfil </b-th>
              <b-th> Ação </b-th>
            </b-thead>
            <b-tbody>
              <b-tr :key="indextr" v-for="(tr, indextr) in tenants">
                <b-td>
                  <b-avatar
                    v-if="!tr.photo"
                    :src="require('@/assets/images/avatars/7-small.png')"
                    size="40px"
                    class="bg-color-avatar"
                    rounded
                  />
                  <b-avatar
                    v-else
                    class="bg-color-avatar"
                    :src="tr.photo"
                    size="40px"
                    rounded
                  />
                  {{ tr.company_name }}</b-td
                >
                <b-td class="text-center">{{ tr.plan.name }}</b-td>
                <b-td v-if="tr.status == '0'" class="text-center">
                  <b-badge variant="danger" class="badge-glow">
                    Desativado
                  </b-badge>
                </b-td>
                <b-td v-if="tr.status == '1'" class="text-center">
                  <b-badge variant="success" class="badge-glow">
                    Ativado
                  </b-badge>
                </b-td>
                <b-td class="text-center">{{
                  tr.created_at | dateFormat
                }}</b-td>
                <b-td class="text-center">
                  <b-button
                    variant="relief-danger"
                    pill
                    size="sm"
                    :to="{ path: '/clientes/' + tr.id + '/perfil' }"
                  >
                    Perfil
                  </b-button>
                </b-td>
                <b-td class="text-center">
                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-warning"
                    class="btn-icon rounded-circle"
                  >
                    <feather-icon icon="Edit2Icon" />
                  </b-button>

                  <b-button
                    v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                    variant="flat-danger"
                    class="btn-icon rounded-circle"
                    v-b-modal.modal-delete-tenants
                  >
                    <feather-icon icon="Trash2Icon" />
                  </b-button>
                </b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-overlay>
        <b-pagination
          @change="handlePageChange"
          v-model="currentPage"
          :per-page="perPage"
          :total-rows="totalRows"
          aria-controls="tableTenants"
          class="mt-1"
        />
        <delete-tenants />
      </div>
      <div v-show="showCreate">
          <div class="demo-vertical-spacing">
            <b-row>
              <b-col md="6" xl="6">
                <!-- Name -->
                <label>Nome da empresa</label>
                <b-form-input
                  placeholder="Nome"
                  v-model="dateTenants.company_name"
                />
              </b-col>
              <b-col md="6" xl="6">
                <!-- Plans -->
                <label>Plano</label>
                <b-form-select
                  text-field="name"
                  value-field="id"
                  v-model="dateTenants.plan_id"
                  :options="plans"
                  id="plan"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6" xl="6">
                <!-- Photo -->
                <label>Foto</label>
                <b-form-file 
                  placeholder="Foto" 
                  v-model="dateTenants.photo" 
                  accept="image/*" 
                  type="file"
                />
              </b-col>
              <b-col md="6" xl="6">
                <!-- Status -->
                <label>Status</label>
                <b-form-select
                  v-model="dateTenants.status"
                  :options="statusTenantOptions"
                  id="plan"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6" xl="6">
                <!-- Mail -->
                <label>E-mail</label>
                <b-form-input
                  placeholder="E-mail"
                  v-model="dateTenants.email"
                />
              </b-col>
              <b-col md="6" xl="6">
                <!-- Phone -->
                <label>Telefone</label>
                <b-form-input
                  placeholder="Telefone"
                  v-model="dateTenants.phone"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4" xl="4">
                <!-- Name -->
                <label>Rua</label>
                <b-form-input placeholder="Rua" v-model="dateTenants.street" />
              </b-col>
              <b-col md="4" xl="4">
                <!-- Name -->
                <label>Numero</label>
                <b-form-input
                  placeholder="Numero"
                  v-model="dateTenants.number"
                />
              </b-col>
              <b-col md="4" xl="4">
                <!-- Plans -->
                <label>CEP</label>
                <b-form-input
                  placeholder="CEP"
                  v-model="dateTenants.zip_code"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="4" xl="4">
                <!-- Name -->
                <label>Bairro</label>
                <b-form-input
                  placeholder="Bairro"
                  v-model="dateTenants.neighborhood"
                />
              </b-col>
              <b-col md="4" xl="4">
                <!-- Plans -->
                <label>Estado</label>
                <b-form-select
                  v-model="dateTenants.state"
                  :options="stateTenantOptions"
                  id="state"
                  @change="Citys"
                />
              </b-col>
              <b-col md="4" xl="4">
                <!-- Name -->
                <label>Cidade</label>
                <b-form-select
                  text-field="name"
                  value-field="id"
                  v-model="dateTenants.city"
                  :options="cities"
                  id="plan"
                />
              </b-col>
            </b-row>

            <b-row>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="success"
                class="mr-1"
                @click="storeTenants"
              >
                Cadastrar
              </b-button>

              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="danger"
                @click="showCreate = false"
              >
                Cancelar
              </b-button>
            </b-row>
          </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/css/swiper.css";
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import { BButton } from "bootstrap-vue";
import DeleteTenants from "@/components/tenants/modals/DeleteTenants";
// import vSelect from "vue-select";

export default {
  components: {
    BButton,
    Swiper,
    SwiperSlide,
    DeleteTenants,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      search: null,

      showSearch: false,
      showCreate: false,

      isLoading: true,

      id: 16,

      states: null,

      statusTenant: null,
      statusTenantOptions: [
        { value: null, text: "Selecione um status" },
        { value: "1", text: "Ativo" },
        { value: "0", text: "Desativado" },
      ],
      stateTenantOptions: [
        { value: null, text: "Selecione um estado" },
        { value: "16", text: "Paraná" },
      ],

      dateTenants: {
        company_name: null,
        plan_id: null,
        status: null,
        email: null,
        phone: null,
        street: null,
        number: null,
        zip_code: null,
        neighborhood: null,
        city: null,
        state: null,
        photo: null,
      },
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        pagination: {
          el: ".swiper-pagination",
          type: "progressbar",
        },
      },
    };
  },
  computed: {
    ...mapState("tenants", ["tenants", "pagination"]),
    ...mapState("plans", ["plans", "pagination"]),
    ...mapState("cities", ["cities"]),
  },
  methods: {
    ...mapActions("tenants", ["store"]),
    ...mapActions("plans", ["fetchPlans"]),
    ...mapActions("cities", ["fetchCities"]),
    storeTenants() {
      this.store({
        ...this.dateTenants,
      });
    },
    Citys() {
      this.states = this.dateTenants.state

      if(this.states != null){
        this.fetchCities({
          id: this.id,
        });
      }
    },
    fetchTenants(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("tenants/fetchTenants", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })
        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePageChange(value) {
      this.fetchTenants(true, value);
    },
  },
  created() {
    this.fetchCities({
      id: this.dateTenants.state,
    });
    this.fetchTenants();
    this.fetchPlans({
      pagination: false,
    });
  },
  watch: {
    search(val) {
      this.currentPage = 1;
      this.fetchTenants(true, 1);
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

.bg-color-avatar {
  background: none !important;
}
</style>
